.tear-border {
  aspect-ratio:1;
}

.tear-border.bottom {
  -webkit-mask:
    radial-gradient(circle 8px at bottom, transparent 97%, #fff 100%) bottom/25px 100%,
    linear-gradient(#fff 0 0) left/0px 100% no-repeat,
    linear-gradient(#fff 0 0) right/0px 100% no-repeat;
}

.tear-border.top {
  -webkit-mask:
    radial-gradient(circle 8px at top, transparent 97%, #fff 100%) top/25px 100%,
    linear-gradient(#fff 0 0) left/0px 100% no-repeat,
    linear-gradient(#fff 0 0) right/0px 100% no-repeat;
}
