body {
  margin: 0;
  font-family: 'SvenskaSpelPlatform', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
/* add custom utility classes here that are not available in default tailwind configuration */
@layer utilities {
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
}

@font-face {
  font-family: 'SvenskaSpelPlatform';
  src: url("./assets/SvenskaSpelPlatform-Regular-Web.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SvenskaSpelPlatform';
  src: url("./assets/SvenskaSpelPlatform-Medium-Web.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SvenskaSpelPlatform';
  src: url("./assets/SvenskaSpelPlatform-Bold-Web.woff2") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Svenska Spel Platform';
  src: url("./assets/SvenskaSpelPlatform-Regular-Web.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Svenska Spel Platform';
  src: url("./assets/SvenskaSpelPlatform-Medium-Web.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Svenska Spel Platform';
  src: url("./assets/SvenskaSpelPlatform-Bold-Web.woff2") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@layer base {
  html {
    font-family: 'SvenskaSpelPlatform';
  }
}

@layer components {
  .shadow-card {
    box-shadow: 0px 1.15541px 3.46622px rgba(0, 0, 0, 0.12), 0px 1.15541px 1.15541px rgba(0, 0, 0, 0.14), 0px 2.31081px 1.15541px -1.15541px rgba(0, 0, 0, 0.2);
  }

  .shadow-team-card {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  }

  .shadow-team-selector {
    box-shadow: 0px 1.28218px 6.41088px rgba(0, 0, 0, 0.12), 0px 2.56435px 2.56435px rgba(0, 0, 0, 0.14), 0px 3.84653px 1.28218px -2.56435px rgba(0, 0, 0, 0.2);
  }

  .shadow-dialog {
    filter: drop-shadow(0px -5px 24px rgba(0, 0, 0, 0.2));
  }
}
