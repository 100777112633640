.banner-custom-rounded-border::after {
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-image: url('../../assets/white-overlay.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  margin-bottom: -1px;
}

@media screen and (min-width: 400px) {
  .banner-custom-rounded-border::after {
    height: 40px;
    background-size: 170%;
  }
}

@media screen and (min-width: 1020px) {
  .banner-custom-rounded-border::after {
    height: 200px;
    background-size: 160%;
  }
}
